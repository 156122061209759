import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { OverlayAlertConfig } from '../overlay-alert-config';
@Component({
  selector: 'ig-overlay-alert-prompt',
  templateUrl: './ig-overlay-alert-prompt.component.html',
  styleUrls: ['./ig-overlay-alert-prompt.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IgOverlayAlertPromptComponent implements OnInit {

  inputLabel?: string;
  inputPattern?: RegExp;
  initialValue?: string;

  userInput = '';

  valid = false;

  constructor(private readonly alertConfig: OverlayAlertConfig<IgOverlayAlertPromptComponent>, private readonly ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.inputLabel = this.alertConfig?.data?.inputLabel;
    this.inputPattern = this.alertConfig?.data?.inputPattern;

    if (this.alertConfig?.data?.initialValue !== undefined) {
      this.userInput = this.alertConfig.data.initialValue;
    }
    this.validate();
  }

  validate() {
    if (_.isEmpty(this.userInput)) {
      this.valid = false;
    } else if (this.inputPattern && !this.userInput.match(this.inputPattern)) {
      this.valid = false;
    } else {
      this.valid = true;
    }



    _.set(this.alertConfig, ['buttons', 1, 'disabled'], !this.valid);
    this.ref.markForCheck();
  }

}
