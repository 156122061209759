import {
  Component,
  ComponentRef,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as _ from 'lodash';
import { Subject, of, take } from 'rxjs';
import { UiTranslateService } from '../../helper/lang/ui-translate.service';
import { ZIndexUtils } from 'primeng/utils';
import { PrimeNGConfig } from 'primeng/api';

export enum OverlayAlertSeverity {
  error = 'error',
  warning = 'warning',
  success = 'success',
  info = 'info',
  question = 'question'
}

export interface Button {
  /**
   * display label for the button
   */
  label: string;
  /**
   * a button Mode (all possible modes from `ig-button`)
   */
  mode: string;
  /**
   * an optional icon class for if an icon should be displayed in the button
   */
  icon?: string;
  /**
   * An optional keyboard shortcur that will trigger the button. Possible values are all values of `KeyboardEvent.Key`(https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values)
   */
  keyShortcut?: 'Enter' | 'Escape';
  /**
   * This is a callback function that is called when the button is hit (either by mouse or keyShortcut). It will receive three parameters:
   * @param button The Button object of the button itself (so the button config you are just defining at the moment)
   * @param alertInstance The instance of OverlayAlertComponent, where you have methods like close() to close the overlay alert or setLoading to set a loading state of the alert
   * @param childComponentRef only for custom() dialogs The reference to the component you are displaying within the overlay alert. Use childComponentRef.instance to access the component instance and access all public methods/properties of this component
   */
  callback?(button: Button, alertInstance: OverlayAlertComponent, childComponentRef?: ComponentRef<any>): void;
  /**
   * whether to disable the button
   */
  disabled?: boolean;
  /**
   * optional tooltip to show on hover
   */
  tooltip?: string;
}

export interface ButtonWithChildComponentType<T> extends Button {
  callback?(button: Button, alertInstance: OverlayAlertComponent, childComponentRef?: ComponentRef<T>): void;
}

// tslint:disable-next-line: no-conflicting-lifecycle
@Component({
  selector: 'ig-overlay-alert',
  templateUrl: './overlay-alert.component.html',
  styleUrls: ['./overlay-alert.component.scss']
  // animations: [
  //   trigger('openClose', [
  //     state('open', style({
  //       transform: 'scale3d(1,1,1)'
  //     })),
  //     state('closed', style({
  //       transform: 'scale3d(1,0,1)'
  //     })),
  //     transition('* => open', [
  //       animate('0.2s ease-in-out')
  //     ])
  //   ])
  // ]
})
export class OverlayAlertComponent implements OnInit, OnDestroy, DoCheck, OnChanges {
  private rootEl: any;

  /**
   * Defines the severity of the alert (in non-advanced mode) (defaults to "warning")
   */
  @Input() severity: OverlayAlertSeverity = OverlayAlertSeverity.warning;

  /**
   * Defines the class of the icon, displayed in the header (will use default icon when not in advanced mode, depending on severity)
   */
  @Input() iconCls = '';

  /**
   * The text of the headline
   */
  @Input() headline?: string;

  /**
   * The text of the optional subheadline - can also contain HTML
   */
  @Input() subheadline?: string;

  /**
   * Your message (in non-advanced mode)
   */
  @Input() message?: string;

  /**
   * Set the loading state (when you do requests)
   */
  @Input() loading = false;

  /**
   * custom style class
   */
  @Input() styleClass?: string;

  /**
   * the reference to a child component (when called from service with custom content component)
   */
  @Input() childComponentRef?: ComponentRef<any>;

  @Input() baseZIndex?: number;

  /**
   * Custom buttons the callback receives the clicked button as parameter
   */
  @Input() buttons: Button[] | string = [
    {
      label: this.uiTranslate.instant('no'),
      mode: 'secondary',
      icon: '',
      keyShortcut: 'Escape',
      callback: () => {
        this.callback.emit(false);
        this.close();
      }
    },
    {
      label: this.uiTranslate.instant('yes'),
      mode: 'primary',
      callback: () => {
        this.callback.emit(true);
        this.close();
      }
    }
  ];

  /**
   * In advanced mode you may provide custom content and custom icon
   */
  @Input() advancedMode = false;


  /**
   * In advanced alignment mode the buttons are right aligned and the headline left aligned
   */
  @Input() advancedAlignmentMode = false;


  /**
   * Set a mandatory label when there are mandatory fields in a form
   */
  @Input() mandatoryLabel = false;


  /**
   * Causes that the alert will be shown after initialization
   */
  @Input() initialShow = false;

  /**
   * Sets another overlay width
   */
  @Input() width?: string;

  /**
   * Use fullwidth mode for advanced content (without paddings)
   */
  @Input() fullwidthContent = false;

  /**
   * Allow that multiple alerts are shown (default: false - will remove other alerts (either calls the close() method of the component if a reference is found or just removed the element from DOM)
   */
  @Input() allowMultipleAlerts = false;

  /**
   * shows a close button on the upper right
   */
  @Input() showCloseButton = true;

  /**
   * shows a back button on the upper left corner
   */
   @Input() showBackButton = false;

  /**
   * add an inline alert below the message for additional attention
   */
  @Input() includeInlineAlert?: {
    severity: string;
    message: string;
    lightStyle?: boolean;
  };

  /**
   * set a hook function which is called before the dialog should close. The function must return an Observable. If its value is false, the close will be prevented
   */
  @Input() onBeforeClose = () => of(true);

  /**
   * @ignore
   */
  @Input() hasCustomContentComponent = false;

  /**
   * Enables close on click outside the alert window (default: true)
   */
  @Input() closeOnBackground = true;

  /**
   * Prevents the overlay to be closed in other way than submit button
   */
  @Input() isModal = false;

  /**
   * @ignore
   */
  @Input() hideContentArea = false;

  /**
   * places a hidden field catching the focus
   */
  @Input() catchFocus = true;

  /**
   * @ignore
   */
  onInit$: Subject<void> = new Subject();
  /**
   * @ignore
   */
  onDestroy$: Subject<void> = new Subject();
  /**
   * @ignore
   */
  doCheck$: Subject<void> = new Subject();

  /**
   * @ignore
   */
  isOpen = false;

  /**
   * @ignore
   */
  hidden = true;

  /**
   * @ignore
   */
  myButtons: Button[] = [];

  /**
   * Default callback, when you not define custom buttons with callbacks
   * (emits with boolean when not defining buttons, or the button when defining button without callback)
   */
  @Output() callback: EventEmitter<boolean | Button> = new EventEmitter();

  /**
   * is emitted when alert content was scolled
   */
  @Output() contentScrolled = new EventEmitter<{ percentage: number }>();


  /**
   * is emitted when back button is clicked
   */
  @Output() backButtonClicked = new EventEmitter();

  /**
   * @ignore
   */
  subheadlineTrusted?: SafeHtml;

  private escKeyListener?: () => void;
  private enterKeyListener?: () => void;

  private insideClickListener?: () => void;

  /**
   * @ignore
   */
  @ViewChild('catchFocusEl') catchFocusEl?: ElementRef<HTMLElement>;

  /**
   * @ignore
   */
  @ViewChild('container') containerEl?: ElementRef<HTMLElement>;


  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private zone: NgZone,
    private sanitizer: DomSanitizer,
    private readonly uiTranslate: UiTranslateService,
    public config: PrimeNGConfig
  ) {
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.width === undefined) {
      this.width = Math.max(470, this.buttons?.length * 230) + 'px';
    }

    this.onInit$.next();

    this.processButtons();

    if (this.initialShow) {
      this.show();
    }

    this.escKeyListener = this.renderer.listen(this.el.nativeElement, 'keydown.esc', (event) => this.closeBtnClick(event));
    this.enterKeyListener = this.renderer.listen(this.el.nativeElement, 'keydown.enter', (event) => this.onEnter(event));

    if (this.subheadline) {
      this.subheadlineTrusted = this.sanitizer.bypassSecurityTrustHtml(this.subheadline);
    }

  }

  /**
   * Shows the overlay
   */
  show() {
    this.insideClickListener = this.renderer.listen(this.el.nativeElement, 'mouseup', (event) => {
      if (document.activeElement?.tagName.toLowerCase() === 'body') {
        this.catchFocusEl?.nativeElement.focus();
      }
    });

    this.zone.runOutsideAngular(() => {
      // ensure we have only one visible element of us
      if (!this.allowMultipleAlerts) {
        const clones = document.getElementsByTagName(this.el.nativeElement.tagName);
        _.forEach(clones, clone => {
          if (clone && clone !== this.el.nativeElement && clone.querySelector) {
            if (clone.querySelector('.ig-overlay-alert__container')) {
              const cloneId = clone.getAttribute('id');
              if (cloneId && _.get(window, ['__ig-overlay-alerts', cloneId, 'close'])) {
                _.get(window, ['__ig-overlay-alerts', cloneId])?.close();
              } else {
                // there is no component instance for that clone, so just remove from DOM
                this.renderer.removeChild(clone.parentNode, clone);
              }
            }
          }
        });
      }

      // get possible root (we need to place it as much on the top as possible in the DOM but better within angular controlled areas)
      let root: any = document.getElementsByTagName('app-root');
      if (root.length === 1) {
        this.rootEl = root[0];
      } else {
        root = document.getElementById('root');
        if (root) {
          this.rootEl = root;
        } else {
          this.rootEl = document.body;
        }
      }

      document.body.classList.add('no-scrolling');
      this.hidden = false;

      this.zone.run(() => {
        this.isOpen = true;
      });

      // place the overlay bg and append our component directly to it
      this.renderer.appendChild(this.rootEl, this.el.nativeElement);

      const id = _.uniqueId('ig-overlayalert');
      _.set(window, ['__ig-overlay-alerts'], _.get(window, ['__ig-overlay-alerts'], {}));
      this.el.nativeElement.setAttribute('id', id);
      _.set(window, ['__ig-overlay-alerts', id], this);

      window.setTimeout(() => {
        this.setFocus();
        
        ZIndexUtils.set('ig-overlay-alert', this.containerEl?.nativeElement, this.baseZIndex || this.config.zIndex.modal);
      });

      window.setTimeout(() => {
        ZIndexUtils.set('ig-overlay-alert', this.containerEl?.nativeElement, this.baseZIndex || this.config.zIndex.modal);

        //this.setFocus(); // here the delay is causing the focus to be set too late
      }, 100);
    });
  }

  backBtnClick(event?: KeyboardEvent) {
    if (!this.isOpen) {
      return;
    }

    this.close();
    this.backButtonClicked.emit();
  }

  setFocus() {
    const input = this.el.nativeElement.querySelector(
      'input.p-inputtext,input[type=text]:not([disabled]),input[type=password]:not([disabled]),input[type=email]:not([disabled]),textarea:not([disabled])'
    );
    if (input) {
      input.focus();
    } else if (this.catchFocusEl?.nativeElement && this.myButtons.length !== 1) {
      // focus the hidden element to get the focus in the alert. If there is exaclty 1 button, it will get the focus via ig-button component
      this.catchFocusEl.nativeElement.focus();
    }
  }

  closeBtnClick(event?: KeyboardEvent) {
    if (!this.isOpen || this.isModal) {
      return;
    }

    // check if we have buttons with bound keyboard shortcuts
    if (_.isArray(this.buttons) && this.buttons.length > 0) {
      let closeButtonFound = false;
      _.forEach(this.buttons, (button, btnIndex) => {
        if (!button.disabled && button.keyShortcut && event?.key && button.keyShortcut.toLowerCase() === event.key.toLowerCase()) {
          event?.preventDefault();
          this.buttonClick(btnIndex);
          closeButtonFound = true;
          return false;
        }
        if (event === undefined && !button.disabled && button.keyShortcut?.toLowerCase() === 'escape') {
          this.buttonClick(btnIndex);
          closeButtonFound = true;
          return false;
        }

        return true;
      });
      if (!closeButtonFound) {
        this.close();
      }
    } else if (_.isString(this.buttons) && event?.key === 'Escape') {
      // we have a single button and assume that closing on escape is okay
      this.close();
    } else {
      this.close();
    }
  }

  onEnter(event?: KeyboardEvent) {
    if (!this.isOpen) {
      return;
    }

    // check if we have buttons with bound keyboard shortcuts
    if (_.isArray(this.buttons) && this.buttons.length > 0) {
      _.forEach(this.buttons, (button, btnIndex) => {
        if (!button.disabled && button.keyShortcut && event?.key && button.keyShortcut.toLowerCase() === event.key.toLowerCase()) {
          event?.preventDefault();
          this.buttonClick(btnIndex);
          return false;
        }

        return true;
      });
    }

  }

  /**
   * closes the dialog and removes it from dom.
   */
  close() {
    this.onBeforeClose().pipe(
      take(1)
    ).subscribe(closeAllowed => {
      if (closeAllowed === false) {
        return;
      }

      this.onDestroy$.next();
      this.onDestroy$.complete();
      this.onInit$.complete();
      this.doCheck$.complete();

      this.renderer.removeChild(this.rootEl, this.el.nativeElement);
      document.body.classList.remove('no-scrolling');

      this.hidden = true;
      this.isOpen = false;

      if (this.escKeyListener) {
        this.escKeyListener();
      }
      if (this.enterKeyListener) {
        this.enterKeyListener();
      }

      this.insideClickListener?.();
    });

  }

  /**
   * @ignore
   */
  buttonClick(btnIndex: number) {
    if (this.myButtons[btnIndex]?.callback) {
      this.myButtons[btnIndex].callback!(this.myButtons[btnIndex], this, this.childComponentRef);
    } else {
      this.close();
      this.callback.emit(this.myButtons[btnIndex]);
    }
  }

  /**
   * @ignore
   */
  ngDoCheck() {
    this.doCheck$.next();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['buttons']) {
      this.processButtons();
    }

    if (changes['subheadline'] && this.subheadline) {
      this.subheadlineTrusted = this.sanitizer.bypassSecurityTrustHtml(this.subheadline);
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.close();
  }

  onClickOutside() {
    if (this.closeOnBackground) {
      this.closeBtnClick();
    }
  }

  get showHeadline() {
    return !_.isEmpty(this.headline) || !_.isEmpty(this.iconCls);
  }

  /**
   * convert string button to a default button
   */
  private processButtons() {
    if (typeof this.buttons === 'string') {
      this.myButtons = [
        {
          label: this.buttons,
          mode: 'primary',
          callback: () => {
            this.callback.emit(this.myButtons[0]);
            this.close();
          }
        }
      ];
    } else {
      this.myButtons = this.buttons;
    }
  }

  onAlertScroll(event: Event) {
    const scrollTopMax = _.toInteger((event.target as HTMLElement)?.scrollHeight) - _.toInteger((event.target as HTMLElement)?.clientHeight);
    const percentage = Math.round(_.toInteger((event.target as HTMLElement)?.scrollTop) / scrollTopMax * 100);

    this.contentScrolled.emit({ percentage });
  }
}
