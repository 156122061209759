<ng-container *ngIf="options.subobjectLists">
  <div *ngFor="let subobjects of options.subobjectLists | keyvalue:keyValueCompare" class="subobject-list">
    <ng-container *ngIf="hasObjects(subobjects.value)">
      <small class="intro" *ngIf="subobjects.value.intro">{{ subobjects.value.intro }}</small>

      <div class="table-container">
        <table cellspacing=0>
          <thead>
            <tr>
              <th *ngIf="(subobjects.value.checkboxVisibility == 'show') || (canAnythingBeDeselected && subobjects.value.checkboxVisibility != 'hide')" width=40></th>
              <th
                *ngFor="let headline of subobjects.value.listHeadlines ; index as ii"
                [ngStyle]="{ width: getColWidth(subobjects.value, ii) }"
              >
                {{headline}}
                <i
                  *ngIf="getHeadlineTooltip(subobjects.value.listHeadlineTooltips, ii) as tooltip"
                  class="fal fa-info-circle"
                  style="font-size: 1.25em; margin-left: 4px"
                  [igTooltip]="tooltip"
                ></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of subobjects.value.objects | keyvalue:keyValueCompare">
              <td *ngIf="((subobjects.value.checkboxVisibility == 'show') || (canAnythingBeDeselected && subobjects.value.checkboxVisibility != 'hide')) && options?.subobjectLists && options.subobjectLists[subobjects.key]?.objects && options.subobjectLists[subobjects.key]?.objects![row.key]" width=40>
                <p-checkbox [binary]="true" [(ngModel)]="options!.subobjectLists![subobjects.key]!.objects![row.key].selected" [disabled]="row.value.disabled"></p-checkbox>
              </td>
              <td
                *ngFor="let col of row.value.dataColumns; index as i"
                [attr.title]="col | striphtml"
                class="content-col"
                [ngStyle]="{ width: getColWidth(subobjects.value, i) }"
                [innerHTML]="col | markdown"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>

      <p class="note" *ngIf="subobjects.value.note">{{ subobjects.value.note }}</p>
    </ng-container>
  </div>
  <div *ngIf="canAnythingBeDeselected" class="selection-container p-grid p-jc-between p-ai-center">
    <div>
      {{ 'delete_confirm_selected' | uitranslate }}: {{ selectedCount }} {{ (selectedCount === 1) ? ('delete_confirm_objects_singular' | uitranslate) : ('delete_confirm_objects' | uitranslate) }}
    </div>
    <ig-button mode="secondary" size="small" [label]="'delete_confirm_deselect_all' | uitranslate" (onClick)="deselectAll()" *ngIf="selectedCount > 0"></ig-button>
    <ig-button mode="secondary" size="small" [label]="'delete_confirm_select_all' | uitranslate" (onClick)="selectAll()" *ngIf="selectedCount === 0"></ig-button>
  </div>
</ng-container>

<div class="type-to-confirm" *ngIf="options.typeToConfirm !== undefined">
  <form [formGroup]="form">
    <div class="p-fluid">
      <div class="p-field">
        <label for="igOverlayTypeToConfirm" style="text-transform: none;">
          {{ 'delete_confirm_type_to_confirm_label_pre' | uitranslate }}
          &quot;<ig-copy-clipboard [value]="options.typeToConfirm">{{ options.typeToConfirm }}</ig-copy-clipboard>&quot;
          {{ 'delete_confirm_type_to_confirm_label_post' | uitranslate }}
        </label>
        <input id="igOverlayTypeToConfirm" class="p-inputtext" type="text" pInputText formControlName="typetoconfirm">
      </div>
    </div>
  </form>
</div>

<ig-inline-alert *ngIf="options.cannotBeUndone" severity="warning">
  <ng-container  *ngIf="options.cannotBeUndoneText; else defaultWarning">
    {{ options.cannotBeUndoneText }}
  </ng-container>
  <ng-template #defaultWarning>{{ criticalWarningText }}</ng-template>
</ig-inline-alert>

