import { EnvironmentInjector, Injectable } from '@angular/core';
import { Button, ButtonWithChildComponentType, OverlayAlertSeverity } from './overlay-alert.component';
import { InlineAlertSeverity } from '../../elements/inline-alert/inline-alert.component';
import { OverlayAlertCustomComponent } from './overlay-alert.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayAlertConfig<T = unknown> {
  /**
   * if true, the component is opened by OverlayAlertService
   * this will be set to true, when the config is injected by the OverlayAlertService
   */
  isOverlayAlert? = false;
  /**
   * an optional object for arbitary data to pass to the custom component
   * The data is then accessible in the component when injecting `OverlayAlertConfig`
   */
  data?: (T & OverlayAlertCustomComponent)['__overlayAlertConfigData'];
  /**
   * an optional icon class if an icon should be displayed in the header (if no `headline` and no `iconCls` is given there will ne no header)
   */
  iconCls?: string;
  /**
   * an optional headline that should be displayed in the header (if no `headline` and no `iconCls` is given there will ne no header)
   */
  headline?: string;
  /**
   * an optional subheadline that should be displayed below the `headline`
   */
  subheadline?: string;
  /**
   * an optional width of the dialog. Can have all css supported units, like px, em, vw... but it **must** have a unit
   */
  width?: string;
  /**
   * optional button configuration (if not provided there will be a "yes" and a "no" button)
   */
  buttons?: ButtonWithChildComponentType<T>[];
  /**
   * set true to have full width for the content (without padding, e.g. in combination with ig-panelbox)
   */
  fullwidthContent?: boolean;
  /**
   * set to false to only allow one open alert, it will close all overlay-alerts before displaying a new one
   */
  allowMultipleAlerts?: boolean;
  /**
   * an optional style class to apply to the dialog container
   */
  styleClass?: string;
  /**
   * A callback function that is executed for confirm dialogs, receiving a boolean with `true` if confirmation was positive and `false` otherwise. **ATTENTION** If you provide custom buttons with callbacks you have to emit `childComponentRef.instance.callback(confirmed: boolean)`
   */
  callback?: (confirmed: boolean | Button) => void;
  /**
   * whether to show a close button on the top right
   */
  showCloseButton?: boolean;
  /**
   * whether to show a back button on the top left
   */
  showBackButton?: boolean;
  /**
   * internal flag if it should be a new confirmation dialog type
   */
  isDeleteContainer?: boolean;
  /**
   * an internal flag to hide the content area
   */
  hideContentArea?: boolean;
  /**
   * when set, an ig-inline-alert component will be appended to the dialog, the `severity` must have a value of `InlineAlertSeverity`the `message` can contain markdown
   */
  includeInlineAlert?: {
    severity: InlineAlertSeverity | string;
    message: string;
    lightStyle?: boolean
  };
  /**
   * provide an instance of `EnvironmentInjector` if you use a custom dialog, to have the custom component running in the module environment of the opening component
   */
  environmentInjector?: EnvironmentInjector
  /**
   * message to display (can contain markdown)
   */
  message?: string;
  /**
   * only for alerts
   */
  severity?: OverlayAlertSeverity;
  advancedAlignmentMode?: boolean;
  mandatoryLabel?: boolean
  /**
   * whether to add a hidden field catching the focus into the alert
   */
  catchFocus?: boolean;

  /**
   * whether to close the alert on click outside the alert window
   */
  closeOnBackground?: boolean;

  /**
   * optional base z-index; the z-index which the overlay has and will be auto incremented with the number of open dialogs (defaults to 2000)
   */
  baseZIndex?: number;

  /**
   * prevents the overlay to be closed in other way than submit button
   */
  isModal?: boolean;
}
