import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ig-overlay-alert-content',
  template: `<ng-content></ng-content>`
})
export class IgOverlayAlertContentComponent {





}
