<div
  class="ig-overlay-alert__container"
  [class.ig-overlay-alert--advanced]="advancedMode"
  [class.ig-overlay-alert--full-width]="fullwidthContent"
  [class.ig-overlay-alert--advanced-alignment]="advancedAlignmentMode"
  [ngClass]="styleClass"
  *ngIf="!hidden"
  #container
>

  <a *ngIf="catchFocus && myButtons.length !== 1" #catchFocusEl href="javascript:void(0)"></a>

  <div class="ig-overlay-alert__overlay-bg" (click)="onClickOutside()"></div>

  <div class="ig-overlay-alert__inner-container">


      <a *ngIf="showBackButton" href="javascript:void(0);" role="button" class="ig-overlay-alert__back-btn" (click)="backBtnClick()">
        <i class="icon-a084_back_alt"></i> <span>{{ 'GENERAL.GO_BACK' | uitranslate }}</span>
      </a>

      <a *ngIf="showCloseButton && !isModal" href="javascript:void(0);" role="button" class="ig-overlay-alert__close-btn" (click)="closeBtnClick()">
        <i class="icon-a146_not_available"></i>
      </a>

      <ig-loader *ngIf="loading" size="parentSize"></ig-loader>
      <div class="ig-overlay-alert__content-wrapper" [ngStyle]="{ width: width }" (scroll)="onAlertScroll($event)">
        <div *ngIf="showHeadline" class="ig-overlay-alert__box ig-overlay-alert__header">
          <div *ngIf="!advancedMode && (iconCls === undefined || iconCls === '')" class="ig-overlay-alert__header-icon" [ngSwitch]="severity">
            <i *ngSwitchCase="'error'" class="icon-a146_not_available"></i>
            <i *ngSwitchCase="'warning'" class="icon-a059_warning"></i>
            <i *ngSwitchCase="'info'" class="icon-a120_info_outline"></i>
            <i *ngSwitchCase="'success'" class="icon-a062_check"></i>
            <i *ngSwitchCase="'question'" class="icon-a155_help_alt"></i>
          </div>
          <div *ngIf="iconCls !== ''" class="ig-overlay-alert__header-icon" [ngClass]="iconCls"></div>

          <div class="ig-overlay-alert__headline" [class.no-icon]="!iconCls || iconCls === ''">
            <div>{{ headline }}</div>
          </div>
        </div>

        <div class="ig-overlay-alert__box ig-overlay-alert__content" [class.without-headline]="!showHeadline" *ngIf="!hideContentArea">

          <div *ngIf="subheadline" class="ig-overlay-alert__message" [innerHTML]="subheadlineTrusted"></div>
          <div class="ig-overlay-alert__message" *ngIf="!advancedMode" [innerHTML]="message | markdown:'inline'"></div>

          <ng-content select="ig-overlay-alert-content" *ngIf="advancedMode || hasCustomContentComponent"></ng-content>

          <div *ngIf="includeInlineAlert?.severity" class="ig-overlay-alert__inline-message">
            <ig-inline-alert [severity]="includeInlineAlert!.severity" [lightStyle]="!!includeInlineAlert!.lightStyle">
              <div [innerHTML]="includeInlineAlert!.message | markdown:'inline'"></div>
            </ig-inline-alert>
          </div>
        </div>

        <div *ngIf="myButtons.length" class="ig-overlay-alert__box ig-overlay-alert__footer">
          <div *ngIf="mandatoryLabel" class="ig-overlay-alert__mandatory-label">{{'form_required_hint' | uitranslate}}</div>
          <ig-button
            *ngFor="let button of myButtons; index as btnIndex"
            [label]="button.label | uitranslate"
            [mode]="button.mode"
            [icon]="button.icon || ''"
            [disabled]="!!button.disabled"
            [autoFocus]="catchFocus && myButtons.length === 1"
            [igTooltip]="button.tooltip"
            tooltipMode="discreet"
            (onClick)="buttonClick(btnIndex)"
          ></ig-button>
        </div>
      </div>


  </div>
</div>



<!--
  <div
  class="ig-overlay-alert-container"
  [class.ig-overlay-alert-advanced]="advancedMode"
  [class.ig-overlay-alert-fullwidth]="fullwidthContent"
  [class.ig-overlay-alert-advanced-alignment]="advancedAlignmentMode"
  [ngClass]="styleClass"
  *ngIf="!hidden"
  #container
>

  <a *ngIf="catchFocus && myButtons.length !== 1" #catchFocusEl href="javascript:void(0)"></a>

  <div class="ig-overlay-alert-overlay-bg" (click)="onClickOutside()"></div>

  <div class="ig-overlay-alert-inner-container">
    <div class="ig-overlay-alert-border-outer" [ngStyle]="{ width: width }">

      <a *ngIf="showBackButton" href="javascript:void(0);" role="button" class="ig-overlay-alert-back-btn" (click)="backBtnClick()">
        <i class="icon-a084_back_alt"></i> <span>{{ 'GENERAL.GO_BACK' | uitranslate }}</span>
      </a>

      <a *ngIf="showCloseButton && !isModal" href="javascript:void(0);" role="button" class="ig-overlay-alert-close-btn" (click)="closeBtnClick()">
        <i class="icon-a146_not_available"></i>
      </a>

      <ig-loader *ngIf="loading" size="parentSize"></ig-loader>
      <div class="ig-overlay-alert-border" [ngStyle]="{ width: width }" (scroll)="onAlertScroll($event)">
        <div *ngIf="showHeadline" class="ig-overlay-alert-box ig-overlay-alert-header">
          <div *ngIf="!advancedMode && (iconCls === undefined || iconCls === '')" class="ig-overlay-alert-icon" [ngSwitch]="severity">
            <i *ngSwitchCase="'error'" class="icon-a146_not_available"></i>
            <i *ngSwitchCase="'warning'" class="icon-a059_warning"></i>
            <i *ngSwitchCase="'info'" class="icon-a120_info_outline"></i>
            <i *ngSwitchCase="'success'" class="icon-a062_check"></i>
            <i *ngSwitchCase="'question'" class="icon-a155_help_alt"></i>
          </div>
          <div *ngIf="iconCls !== ''" class="ig-overlay-alert-icon" [ngClass]="iconCls"></div>

          <div class="ig-overlay-headline" [class.no-icon]="!iconCls || iconCls === ''">
            <div>{{ headline }}</div>
          </div>
        </div>

        <div class="ig-overlay-alert-box ig-overlay-alert-content" [class.without-headline]="!showHeadline" *ngIf="!hideContentArea">

          <div *ngIf="subheadline" class="ig-overlay-message" [innerHTML]="subheadlineTrusted"></div>
          <div class="ig-overlay-message" *ngIf="!advancedMode" [innerHTML]="message | markdown:'inline'"></div>

          <ng-content select="ig-overlay-alert-content" *ngIf="advancedMode || hasCustomContentComponent"></ng-content>

          <div *ngIf="includeInlineAlert?.severity" class="ig-overlay-alert-inline-message">
            <ig-inline-alert [severity]="includeInlineAlert!.severity" [lightStyle]="!!includeInlineAlert!.lightStyle">
              <div [innerHTML]="includeInlineAlert!.message | markdown:'inline'"></div>
            </ig-inline-alert>
          </div>
        </div>

        <div *ngIf="myButtons.length" class="ig-overlay-alert-box ig-overlay-buttons">
          <div *ngIf="mandatoryLabel" class="mandatory_label">{{'form_required_hint' | uitranslate}}</div>
          <ig-button
            *ngFor="let button of myButtons; index as btnIndex"
            [label]="button.label | uitranslate"
            [mode]="button.mode"
            [icon]="button.icon || ''"
            [disabled]="!!button.disabled"
            [autoFocus]="catchFocus && myButtons.length === 1"
            [igTooltip]="button.tooltip"
            tooltipMode="discreet"
            (onClick)="buttonClick(btnIndex)"
          ></ig-button>
        </div>
      </div>
    </div>
  </div>
</div>

-->