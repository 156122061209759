import { Injectable } from '@angular/core';
import { Button } from './overlay-alert.component';
import { Observable, Subject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayAlertRef {


  close(result?: any) {
    this._onClose.next(result);
  }

  setFocus() {
    this._onSetFocus.next(true);
  }

  setLoading(loading: boolean) {
    this._onSetLoading.next(loading);
  }

  updateButton(buttonIndex: number, button: Partial<Button>) {
    this._onUpdateButton.next({ buttonIndex, button });
  }

  private readonly _onClose = new Subject<any>();
  onClose: Observable<any> = this._onClose.asObservable();

  private readonly _onSetFocus = new Subject<boolean>();
  onSetFocus: Observable<boolean> = this._onSetFocus.asObservable();

  private readonly _onSetLoading = new Subject<boolean>();
  onSetLoading: Observable<boolean> = this._onSetLoading.asObservable();

  private readonly _onUpdateButton = new Subject<{ buttonIndex: number, button: Partial<Button> }>();
  onUpdateButton: Observable<{ buttonIndex: number, button: Partial<Button> }> = this._onUpdateButton.asObservable();

  /**
   * set a hook function which is called before the dialog should close. The function must return an Observable. If its value is false, the close will be prevented
   */
  onBeforeClose = () => of(true);
}
