import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { KeyValue } from '@angular/common';
import { DeleteConfirmationOptions } from '../overlay-alert.service';
import { OverlayAlertConfig } from '../overlay-alert-config';
import { OverlayAlertRef } from '../overlay-alert-ref';
import { UiTranslateService } from '../../../helper/lang/ui-translate.service';

@Component({
  selector: 'ig-overlay-delete-confirmation',
  templateUrl: './ig-overlay-delete-confirmation.component.html',
  styleUrls: ['./ig-overlay-delete-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IgOverlayDeleteConfirmationComponent implements OnInit, OnDestroy {

  options: DeleteConfirmationOptions;

  criticalWarningText = this.translate.instant('delete_confirm_critical_warning');

  form = new FormGroup({
    typetoconfirm: new FormControl('', [this.valueValidator()])
  })

  private destroyed$ = new Subject<void>();

  constructor(
    readonly config: OverlayAlertConfig<IgOverlayDeleteConfirmationComponent>,
    private readonly ref: OverlayAlertRef,
    private readonly translate: UiTranslateService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.options = config.data as any;
  }

  ngOnInit(): void {
    if (this.options.typeToConfirm !== undefined && this.config.buttons?.length) {
      for (let i = 1; i < this.config.buttons.length; ++i) {
        this.ref.updateButton(i, {
          disabled: true
        });
      }
    }
    this.form.statusChanges.pipe(
      (takeUntil(this.destroyed$) as unknown as any)
    ).subscribe(state => {
      if (this.config.buttons?.length) {
        for (let i = 1; i < this.config.buttons.length; ++i) {
          this.ref.updateButton(i, {
            disabled: !this.form.valid
          });
        }
      }

      this.cd.markForCheck();
    });

  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  valueValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value !== _.get(this.options, 'typeToConfirm');
      return forbidden ? { forcedValue: { value: control.value } } : null;
    };
  }

  deselectAll() {
    if (this.options.subobjectLists) {
      _.forEach(this.options.subobjectLists, list => {
        if (list.objects) {
          _.forEach(list.objects, object => {
            if (!object.disabled) {
              object.selected = false;
            }
          });
        }
      });

      this.cd.markForCheck();
    }
  }

  selectAll() {
    if (this.options.subobjectLists) {
      _.forEach(this.options.subobjectLists, list => {
        if (list.objects) {
          _.forEach(list.objects, object => {
            if (!object.disabled) {
              object.selected = true;
            }
          });
        }
      });

      this.cd.markForCheck();
    }
  }

  hasObjects(subobjectType: NonNullable<DeleteConfirmationOptions['subobjectLists']>[string]) {
    if (subobjectType.objects && _.size(subobjectType.objects) > 0) {
      return true;
    }
    return false;
  }

  /**
   * get the number of selected objects
   */
  get selectedCount(): number {
    let count = 0;
    if (this.options.subobjectLists) {
      _.forEach(this.options.subobjectLists, list => {
        if (list.objects) {
          _.forEach(list.objects, object => {
            if (object.selected) {
              ++count;
            }
          });
        }
      });
    }
    return count;
  }

  get canAnythingBeDeselected(): boolean {
    let anythingCanBeDeselected = false;
    if (this.options.subobjectLists) {
      _.forEach(this.options.subobjectLists, list => {
        if (list.objects) {
          _.forEach(list.objects, object => {
            if (object.disabled === undefined || object.disabled === false) {
              anythingCanBeDeselected = true;
              return false;
            }

            return true;
          });
        }
      });
    }
    return anythingCanBeDeselected;
  }

  getColWidth(subobjects: NonNullable<DeleteConfirmationOptions['subobjectLists']>[string], index: number) {
    return _.get(subobjects, ['listHeadlineWidths', index], 'auto');
  }


  /**
   * compare function for the `keyValuePipe` using the `order` or `orderByDataColumn field if it is present
   * @param a
   * @param b
   */
  keyValueCompare(a: KeyValue<string, any>, b: KeyValue<string, any>) {
    let compA: string | number = a.key;
    let compB: string | number = b.key;

    if (a.value?.order !== undefined && b.value?.order !== undefined) {
      compA = a.value.order;
      compB = b.value.order;
    }

    if (
      a.value?.orderByDataColumn !== undefined && b.value?.orderByDataColumn !== undefined &&
      a.value?.dataColumns[a.value?.orderByDataColumn] !== undefined && b.value?.dataColumns[b.value?.orderByDataColumn] !== undefined
    ) {
      compA = a.value?.dataColumns[a.value?.orderByDataColumn];
      compB = b.value?.dataColumns[b.value?.orderByDataColumn];
    }


    if (compA > compB) {
      return 1;
    }
    if (compA < compB) {
      return -1;
    }
    return 0;
  }

  getHeadlineTooltip(tooltips: string[], index: number) {
    return _.get(tooltips, index);
  }

}
