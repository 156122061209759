import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayAlertComponent } from './overlay-alert.component';
import { IgOverlayAlertContentComponent } from './ig-overlay-alert-content/ig-overlay-alert-content/ig-overlay-alert-content.component';
import { IgOverlayDeleteConfirmationComponent } from './ig-overlay-delete-confirmation/ig-overlay-delete-confirmation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { IgOverlayAlertPromptComponent } from './ig-overlay-alert-prompt/ig-overlay-alert-prompt.component';
import { ButtonModule } from '../../elements/button/button.module';
import { CopyClipboardModule } from '../copy-clipboard/copy-clipboard.module';
import { InlineAlertModule } from '../../elements/inline-alert/inline-alert.module';
import { LoaderModule } from '../loader/loader.module';
import { SharedModule } from '../../helper/shared.module';
import { TooltipModule } from '../../elements/tooltip';


@NgModule({
  declarations: [OverlayAlertComponent, IgOverlayAlertContentComponent, IgOverlayDeleteConfirmationComponent, IgOverlayAlertPromptComponent],
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    CopyClipboardModule,
    InlineAlertModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TooltipModule
  ],
  exports: [OverlayAlertComponent, IgOverlayAlertContentComponent]
})
export class OverlayAlertModule { }
